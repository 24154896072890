import { useState, useEffect } from "react";
import { supabaseRPC } from "../config/supabaseClient";
import { Container, Grid, TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

// https://fullcalendar.io/docs/plugin-index
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";

import interactionPlugin, {
  ThirdPartyDraggable,
} from "@fullcalendar/interaction";

// TODO: Split these components up!
const Planner = () => {
  const [recipes, setRecipes]: any = useState([]);
  const [events, setEvents]: any = useState([]);
  // useState([
  //   {
  //     title: "Event 1",
  //     start: "2024-02-20T10:00:00",
  //     end: "2024-02-20T10:00:00",
  //   },
  // ]);

  useEffect(() => {
    // Get recipes
    supabaseRPC("get_all_recipes", {}).then((res) => {
      setRecipes(res);
    });

    // TODO: 2/22 LEFT OFF HERE - Enable saving & updating of events
    supabaseRPC("get_user_meal_plan", { p_user_id: 1 }).then((res) => {
      const testEvent = {
        title: res[0].recipe_name,
        start: res[0].start_dtm,
        end: res[0].end_dtm,
      };
      // console.log(testEvent);
      setEvents([testEvent]);
      // console.log({ res });
    });

    // Make recipe stubs draggable to FullCalendar using ThirdPartyDraggable
    // https://fullcalendar.io/docs/third-party-dragging
    // https://fullcalendar.io/docs/external-dragging
    // https://fullcalendar.io/docs/external-dragging-demo
    // https://codepen.io/pen?editors=0100
    // https://codesandbox.io/p/sandbox/fullcalendar-react-draggable-forked-ehr0h?file=%2Fsrc%2FApp.js
    // Prevent multiple re-renders: https://github.com/fullcalendar/fullcalendar-react/issues/118
    const containerEl = document.getElementById("external-recipe-drag-event");
    let recipeDraggable: any;
    if (containerEl) {
      // console.log(containerEl);
      recipeDraggable = new ThirdPartyDraggable(containerEl, {
        itemSelector: ".recipe-stub",
        eventData: function (eventEl) {
          // console.log(eventEl);
          let recipeStubElTitle = eventEl.querySelector("strong");
          let recipeStubElUrl = eventEl.querySelector("img");
          return {
            title: recipeStubElTitle?.innerText, // Recipe Name
            pictureUrl: recipeStubElUrl?.src,
          };
        },
      });
    }

    return () => recipeDraggable.destroy();
  }, []);

  const onDragEnd = (result: any) => {
    const { destination, source } = result;

    // If dropped outside of a droppable area
    if (!destination) {
      return;
    }

    // If dropped at the same position
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // Reorder the recipes based on the drag and drop result
    const updatedRecipes = Array.from(recipes);
    const [removed] = updatedRecipes.splice(source.index, 1);
    updatedRecipes.splice(destination.index, 0, removed);

    setRecipes(updatedRecipes);

    // Here you would typically make an API call to persist the updated order
    // For example:
    // persistRecipeOrder(updatedRecipes);
    // TODO: Decide if you want to actually persist this for each user
  };

  const handleEventDrop = (arg: any) => {
    console.log(arg);

    // const updatedEvent = {
    //   id: arg.event.id,
    //   start: arg.event.startStr,
    //   end: arg.event.endStr,
    // };

    // Call API to update events
  };

  const handleEventReceive = (arg: any) => {
    // Similar to eventDrop but for 3rd party draggable
    console.log(arg);

    // Call API to update events
  };

  const eventContent = (arg: any) => {
    // console.log("dynamicEventContent ran");
    // console.log(arg);
    // console.log(arg.event.extendedProps.pictureUrl);

    const view = arg.view.type;
    // console.log(view);

    // const [isHovered, setIsHovered] = useState(false);

    // const handleMouseEnter = () => {
    //   console.log("Mouse Enter");
    // };
    //
    // const handleMouseLeave = () => {
    //   console.log("Mouse Leave");
    // };

    // TODO: Update CSS on hover for non-all day events -> different from all day events in dayGridMonthView
    // TODO: Add on hover/drag effects to events -> maybe use the darkest color for drag/hover and add a border to pop? Then use lighter color for render?

    // const dayGridMonthEventContent = (
    //   <Box
    //     className="test"
    //     // onMouseEnter={handleMouseEnter}
    //     // onMouseLeave={handleMouseLeave}
    //   >
    //     {arg.event.title}test
    //   </Box>
    // );

    const timeGridWeekEventContent = (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingRight="10px"
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
      >
        <Box display="flex" alignItems="center">
          <img
            src={arg.event.extendedProps.pictureUrl}
            alt=""
            style={{
              width: "60px",
              height: "60px",
              marginRight: "10px",
              padding: "5px",
            }}
          />
          <p>{arg.event.title}</p>
        </Box>
      </Box>
    );

    return view === "timeGridWeek" ? timeGridWeekEventContent : true;
    // : dayGridMonthEventContent;
    // Returning true gives default rendering: https://github.com/fullcalendar/fullcalendar/issues/7110
  };

  const eventClassNames = (arg: any) => {
    // console.log("dynamicEventClasses ran");
    // console.log(event);

    const allDayEvent = arg.event.allDay;
    const startTime = arg.event.start.getHours();

    let eventClass = "";
    if (allDayEvent) {
      eventClass = "all-day-event";
    } else if (startTime >= 18) {
      eventClass = "snack-event";
    } else if (startTime >= 12) {
      eventClass = "dinner-event";
    } else if (startTime >= 6) {
      eventClass = "lunch-event";
    } else {
      eventClass = "breakfast-event";
    }

    return [eventClass];
  };

  /* TODO: One day maybe, figure out how to use the same drag animation in timeGridWeek as in dayGridMonth */
  // const eventDragStart = (arg: any) => {
  //   console.log(arg);
  //   return true;
  // };
  // const eventDragStop = (arg: any) => {
  //   console.log(arg);
  //   return true;
  // };

  return (
    <>
      <Container style={{ maxWidth: "100%", marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <h2>Recipe Queue & Mealz Plan</h2>
            <TextField
              // id="search-bar"
              // className="text"
              // variant="outlined"
              style={{ width: "100%", marginTop: "29px", marginBottom: "6px" }}
              size="small"
              placeholder="Search Recipe Queue..."
              // onInput={(e) => {
              //   setSearchQuery(e.target.value);
              // }}
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    style={{
                      marginRight: "8px",
                      color: "rgba(0, 0, 0, 0.54)",
                    }}
                  />
                ),
              }}
            />

            <div id="external-recipe-drag-event">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="recipes">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {/* Recipe stubs */}
                      {recipes.map((r: any, index: number) => (
                        <Draggable
                          key={r.recipe_id.toString()}
                          draggableId={r.recipe_id.toString()}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Box
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              key={r.recipe_id}
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              className="recipe-stub"
                              paddingRight="10px"
                              style={
                                snapshot.isDragging
                                  ? {
                                      color: "white",
                                      background: "rgb(25, 118, 210)",
                                      ...provided.draggableProps.style,
                                    }
                                  : { ...provided.draggableProps.style }
                              }
                            >
                              <Box display="flex" alignItems="center">
                                <img
                                  src={r.picture_url}
                                  alt=""
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    marginRight: "10px",
                                    padding: "5px",
                                  }}
                                />
                                <strong>{r.recipe_name}</strong>
                              </Box>
                              {/* Mealz count */}
                              <div className="recipe-stub-mealz-count">
                                {r.mealz}
                              </div>
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </Grid>

          <Grid item xs={12} md={9}>
            <FullCalendar
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                listPlugin,
                interactionPlugin,
              ]}
              initialView="dayGridMonth"
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,dayGridDay,listMonth",
              }}
              buttonText={{
                today: "Today",
                month: "Month",
                week: "Week",
                day: "Day",
                list: "List",
              }}
              height={"930px"}
              allDayText={"Planned"}
              droppable={true}
              // editable={true} // Allows for moving recipes around
              // dayCellContent={{ html: "<p>some text</p>" }}
              events={events}
              eventDrop={handleEventDrop}
              eventReceive={handleEventReceive}
              eventStartEditable={true}
              eventContent={eventContent}
              eventClassNames={eventClassNames}
              // eventMinHeight={90}
              // eventDragStart={eventDragStart}
              // eventDragStop={eventDragStop}
              // eventResizableFromEnd={false}              eventMouseEnter={handleEventMouseEnter}
              // eventAllow={true}
              // allDaySlot={false}
              slotDuration="2:00:00" // Set slot duration to 1 hour
              slotLabelInterval="2:00:00" // Display slot labels for each hour
              slotLabelContent={(arg) => {
                const date = arg.date;
                const hour = date.getHours();

                if (hour === 2) {
                  return "Breakfast";
                } else if (hour === 8) {
                  return "Lunch";
                } else if (hour === 14) {
                  return "Dinner";
                } else if (hour === 20) {
                  return "Snacks";
                } else {
                  return "";
                }
              }}
              // TODO: One day, make the meals AND slots per day completely user-configurable
              slotLaneClassNames={(arg) => {
                const date = arg.date;
                const hour = date?.getHours();

                if (hour && hour >= 18) {
                  return "snack-time-slots";
                } else if (hour && hour >= 12) {
                  return "dinner-time-slots";
                } else if (hour && hour >= 6) {
                  return "lunch-time-slots";
                } else {
                  return "breakfast-time-slots";
                }
              }}
              // TODO: Fix the drop animation onto fullcalendar, shouldn't shoot back
              // TODO: Add mealz reduction on drop
              // TODO: Add save to DB on recipe drop -> have to persist your meal plan
              // TODO: Add remove from calendar & remove from calendar by dragging to trash or outside calendar or back to list?
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Planner;
