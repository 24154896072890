import "./App.css";
import { Route, Routes } from "react-router";
import ResponsiveAppBar from "./components/Navbar";
import Home from "./components/Home";
import Planner from "./components/Planner";
import GroceryList from "./components/GroceryList";
import RecipeLibrary from "./components/RecipeLibrary";
import RecipeImport from "./components/RecipeImport";

function App() {
  return (
    <div className="App">
      <ResponsiveAppBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="Home" element={<Home />} />
        <Route path="Planner" element={<Planner />} />
        <Route path="Grocery List" element={<GroceryList />} />
        <Route path="Recipe Library" element={<RecipeLibrary />} />
        <Route path="Import" element={<RecipeImport />} />
      </Routes>
    </div>
  );
}

export default App;
