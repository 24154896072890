import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { supabaseRPC } from "../config/supabaseClient";
import Grid from "@mui/material/Grid";

interface Ingredient {
  ingredientName: string;
  ingredientId: number;
}

interface MeasurementUnit {
  measurementUnitName: string;
  measurementUnitId: number;
}

interface IngredientLine {
  ingredient: Ingredient | null;
  quantity: string;
  measurementUnit: MeasurementUnit | null;
}

interface Recipe {
  name: string | null;
  rating: number | null;
  description: string | null;
  difficulty: string | null;
  link: string | null;
  pictureLink: string | null;
  mealz: number | null;
  cost: number | null;
  prepTime: number | null;
  cookTime: number | null;
  ingredients: IngredientLine[];
  instructionSteps: string[];
}

export interface AddRecipeFormProps {
  open: boolean;
  onClose: () => void;
  recipeSubmitSnack: (recipeName: string) => void;
}

export default function AddRecipeForm(props: AddRecipeFormProps) {
  const { open, onClose, recipeSubmitSnack: onRecipeSubmit } = props;

  const recipeTemplate: Recipe = {
    name: "",
    rating: null,
    description: "",
    difficulty: "",
    link: "",
    pictureLink: "",
    mealz: null,
    cost: null,
    prepTime: null,
    cookTime: null,
    ingredients: [
      {
        ingredient: null,
        quantity: "",
        measurementUnit: null,
      },
    ],
    instructionSteps: [""],
  };

  const [recipe, setRecipe] = useState<Recipe>(recipeTemplate);

  const [ingredientOptions, setIngredientOptions] = useState<any>();
  const [measurementUnitOptions, setMeasurementUnitOptions] = useState<any>();

  // Collapsable form sections
  const [basicInfoSectionOpen, setBasicInfoSectionOpen] = useState(true);
  const [recipeDetailsSectionOpen, setRecipeDetailsSectionOpen] =
    useState(true);
  const [ingredientsSectionOpen, setIngredientsSectionOpen] = useState(true);
  const [instructionsSectionOpen, setInstructionsSSectionOpen] = useState(true);

  useEffect(() => {
    getMeasurementUnits();
    getIngredients();
  }, []);

  const handleClose = () => {
    onClose();
  };

  const getIngredients = async () => {
    supabaseRPC("get_ingredients", {}).then((res) => {
      setIngredientOptions(res);
    });
  };

  const getMeasurementUnits = async () => {
    supabaseRPC("get_measurement_units", {}).then((res) => {
      setMeasurementUnitOptions(res);
    });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    // Transform recipe ingredients to more database friendly format
    // TODO: Need a new type for database save?
    const transformedRecipeIngredients: any = recipe.ingredients.map(
      (ingredientLine: IngredientLine) => ({
        ingredientId: ingredientLine.ingredient?.ingredientId,
        quantity: parseInt(ingredientLine.quantity, 10),
        measurementUnitId: ingredientLine.measurementUnit?.measurementUnitId,
      }),
    );

    setRecipe({ ...recipe, ingredients: transformedRecipeIngredients });

    // Add recipe to db
    supabaseRPC("add_recipe", { recipe: recipe }).then((res) => {
      console.log(recipe);
    });

    // Close modal and clear form
    handleClose();
    onRecipeSubmit(recipe.name || "");
    setRecipe(recipeTemplate);
  };

  // INGREDIENT LINES
  // TODO: Make the add ingredient line its own component
  const handleAddIngredientLine = () => {
    setRecipe({
      ...recipe,
      ingredients: [
        ...recipe.ingredients,
        { ingredient: null, quantity: "", measurementUnit: null },
      ],
    });
    // TODO: Add autoscroll to bottom when adding ingredient
  };

  const handleRemoveIngredientLine = (index: number) => {
    const updatedControls = [...recipe.ingredients];
    updatedControls.splice(index, 1);
    setRecipe({ ...recipe, ingredients: updatedControls });
  };

  const handleIngredientChange = (index: number, value: Ingredient | null) => {
    const updatedControls = [...recipe.ingredients];
    updatedControls[index].ingredient = value;
    setRecipe({ ...recipe, ingredients: updatedControls });
  };

  const handleQuantityChange = (index: number, value: string) => {
    const updatedControls = [...recipe.ingredients];
    updatedControls[index].quantity = value;
    setRecipe({ ...recipe, ingredients: updatedControls });
  };

  const handleMeasurementUnitChange = (
    index: number,
    value: MeasurementUnit | null,
  ) => {
    const updatedControls = [...recipe.ingredients];
    updatedControls[index].measurementUnit = value;
    setRecipe({ ...recipe, ingredients: updatedControls });
  };

  // INSTRUCTION STEPS
  const handleAddIngredientStep = () => {
    let instructionSteps = [...recipe.instructionSteps, ""];
    setRecipe({ ...recipe, instructionSteps: instructionSteps });

    // TODO: Add autoscroll to bottom when adding ingredient
  };

  const handleRemoveInstructionStep = (index: number) => {
    const updatedArr = [...recipe.instructionSteps];
    updatedArr.splice(index, 1);
    setRecipe({ ...recipe, instructionSteps: updatedArr });
  };

  const handleInstructionStepChange = (index: number, value: string) => {
    const updatedArr = [...recipe.instructionSteps];
    updatedArr[index] = value;
    setRecipe({ ...recipe, instructionSteps: updatedArr });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"md"}
      PaperProps={{
        sx: {
          position: "fixed",
          top: 50,
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" sx={{ mt: 1 }}>
            Add New Recipe
          </Typography>

          <Rating
            value={recipe.rating}
            onChange={(e, newValue) =>
              setRecipe({ ...recipe, rating: newValue })
            }
            size="large"
            sx={{ mt: 1 }}
          />
        </Box>
      </DialogTitle>

      {/* Recipe Form */}
      <form onSubmit={handleSubmit}>
        <Container sx={{ mb: 2 }}>
          {/* Basic Info Section */}
          <Typography variant="h6" mb={1}>
            Basic Info
            {/* Collapse Section */}
            {basicInfoSectionOpen ? (
              <ExpandLess
                sx={{ verticalAlign: "middle" }}
                onClick={() => {
                  setBasicInfoSectionOpen(false);
                }}
              />
            ) : (
              <ExpandMore
                sx={{ verticalAlign: "middle" }}
                onClick={() => {
                  setBasicInfoSectionOpen(true);
                }}
              />
            )}
          </Typography>

          <Collapse in={basicInfoSectionOpen} timeout="auto" unmountOnExit>
            <Stack direction="row" justifyContent="space-between">
              <TextField
                label="Recipe Name"
                size={"small"}
                value={recipe.name}
                onChange={(e) => setRecipe({ ...recipe, name: e.target.value })}
                sx={{ width: 1, mb: 1 }}
              />
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <TextField
                label="Description"
                size={"small"}
                value={recipe.description}
                onChange={(e) =>
                  setRecipe({ ...recipe, description: e.target.value })
                }
                sx={{ width: 0.75, mb: 1, mr: 1 }}
              />

              <Autocomplete
                size={"small"}
                value={recipe.difficulty}
                onChange={(e, newValue) =>
                  setRecipe({ ...recipe, difficulty: newValue })
                }
                options={["Easy", "Medium", "Hard"]}
                sx={{ width: 0.25 }}
                renderInput={(params) => (
                  <TextField {...params} label="Difficulty" />
                )}
              />
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <TextField
                label="Recipe Link"
                size={"small"}
                value={recipe.link}
                onChange={(e) => setRecipe({ ...recipe, link: e.target.value })}
                sx={{ width: 1, mb: 2, mr: 1 }}
              />
              <TextField
                label="Recipe Picture Link"
                size={"small"}
                value={recipe.pictureLink}
                onChange={(e) =>
                  setRecipe({ ...recipe, pictureLink: e.target.value })
                }
                sx={{ width: 1, mb: 2 }}
              />
            </Stack>
          </Collapse>

          {/* Recipe Details Section */}
          <Typography variant="h6" mb={1}>
            Recipe Details
            {/* Collapse Section */}
            {recipeDetailsSectionOpen ? (
              <ExpandLess
                sx={{ verticalAlign: "middle" }}
                onClick={() => {
                  setRecipeDetailsSectionOpen(false);
                }}
              />
            ) : (
              <ExpandMore
                sx={{ verticalAlign: "middle" }}
                onClick={() => {
                  setRecipeDetailsSectionOpen(true);
                }}
              />
            )}
          </Typography>

          <Collapse in={recipeDetailsSectionOpen} timeout="auto" unmountOnExit>
            <Stack direction="row" justifyContent="space-between" mb={1}>
              <TextField
                label="Mealz"
                size={"small"}
                value={recipe.mealz}
                onChange={(e) =>
                  setRecipe({ ...recipe, mealz: +e.target.value })
                }
                type="number"
                sx={{ mr: 1, mb: 2 }}
                inputProps={{
                  min: 0,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />

              <TextField
                label="Cost ($)"
                size={"small"}
                value={recipe.cost}
                onChange={(e) =>
                  setRecipe({ ...recipe, cost: +e.target.value })
                }
                type="number"
                sx={{ mr: 1 }}
                inputProps={{
                  min: 0,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />

              <TextField
                label="Prep (Mins.)"
                size={"small"}
                value={recipe.prepTime}
                onChange={(e) =>
                  setRecipe({ ...recipe, prepTime: +e.target.value })
                }
                type="number"
                sx={{ mr: 1 }}
                inputProps={{
                  min: 0,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />

              <TextField
                label="Cook (Mins.)"
                size={"small"}
                value={recipe.cookTime}
                onChange={(e) =>
                  setRecipe({ ...recipe, cookTime: +e.target.value })
                }
                type="number"
                inputProps={{
                  min: 0,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </Stack>
          </Collapse>

          {/* Ingredients Section */}
          {/* TODO: Build this out */}
          {/* Make the ingredient add cool with lookups and add button etc.*/}
          <Typography variant="h6" mb={1}>
            Ingredients
            {/* Collapse Section */}
            {ingredientsSectionOpen ? (
              <ExpandLess
                sx={{ verticalAlign: "middle" }}
                onClick={() => {
                  setIngredientsSectionOpen(false);
                }}
              />
            ) : (
              <ExpandMore
                sx={{ verticalAlign: "middle" }}
                onClick={() => {
                  setIngredientsSectionOpen(true);
                }}
              />
            )}
          </Typography>

          <Collapse in={ingredientsSectionOpen} timeout="auto" unmountOnExit>
            <div id="my-div" className="recipe-form-ingredients-overflow">
              {recipe.ingredients.map((control, i) => (
                <Grid container key={i}>
                  {/* Ingredient */}
                  {/* TODO: Add in debounce and mechanism for users to add ingredients to user_ingredient table */}
                  <Grid item xs={6}>
                    <Autocomplete
                      size={"small"}
                      sx={{ mb: 1, mr: 1 }}
                      options={ingredientOptions}
                      getOptionLabel={(opt: any) => opt.ingredient_name}
                      value={control.ingredient}
                      onChange={(_, value) => {
                        handleIngredientChange(i, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          placeholder="Ingredient"
                        />
                      )}
                    />
                  </Grid>

                  {/* Quantity */}
                  {/* TODO: Enable support for e.g. 0.5 or 1.5 (floats) */}
                  <Grid item xs={2.5}>
                    <TextField
                      type="number"
                      inputProps={{ min: 0 }}
                      size={"small"}
                      sx={{ mr: 1 }}
                      placeholder="Quantity"
                      value={control.quantity}
                      onChange={(e) => handleQuantityChange(i, e.target.value)}
                    />
                  </Grid>

                  {/* Measurement Unit */}
                  <Grid item xs={3}>
                    <Autocomplete
                      size={"small"}
                      options={measurementUnitOptions}
                      getOptionLabel={(opt: any) => opt.measurement_unit_name}
                      value={control.measurementUnit}
                      onChange={(_, value) =>
                        handleMeasurementUnitChange(i, value)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="" placeholder="Unit" />
                      )}
                    />
                  </Grid>

                  {/* Remove Ingredient Line Icon */}
                  <Grid item xs={0.5}>
                    <RemoveCircleIcon
                      sx={{ mt: 1, ml: 1.5, color: "rgb(180, 37, 37)" }}
                      onClick={() => handleRemoveIngredientLine(i)}
                    />
                  </Grid>
                </Grid>
              ))}
            </div>
          </Collapse>

          <Button
            type="button"
            variant="contained"
            sx={{ mb: 2 }}
            onClick={handleAddIngredientLine}
          >
            + Add Ingredient
          </Button>

          {/* Instructions Section */}
          {/* TODO: Build this out */}
          <Typography variant="h6" mb={1}>
            Instructions
            {/* Collapse Section */}
            {instructionsSectionOpen ? (
              <ExpandLess
                sx={{ verticalAlign: "middle" }}
                onClick={() => {
                  setInstructionsSSectionOpen(false);
                }}
              />
            ) : (
              <ExpandMore
                sx={{ verticalAlign: "middle" }}
                onClick={() => {
                  setInstructionsSSectionOpen(true);
                }}
              />
            )}
          </Typography>

          <Collapse in={instructionsSectionOpen} timeout="auto" unmountOnExit>
            <div id="my-div" className="recipe-form-ingredients-overflow">
              {recipe.instructionSteps.map((step, i) => (
                <Grid container key={i}>
                  <Grid item xs={11.5}>
                    <TextField
                      size="small"
                      placeholder={`Step ${i + 1}`}
                      multiline
                      maxRows={10}
                      sx={{ width: 1, mr: 1, mb: 1 }}
                      value={step}
                      onChange={(e) =>
                        handleInstructionStepChange(i, e.target.value)
                      }
                    />
                  </Grid>

                  <Grid item xs={0.5}>
                    <RemoveCircleIcon
                      sx={{ mt: 1, ml: 1.5, color: "rgb(180, 37, 37)" }}
                      onClick={() => handleRemoveInstructionStep(i)}
                    />
                  </Grid>
                </Grid>
              ))}

              <Button
                type="button"
                variant="contained"
                sx={{ mb: 2 }}
                onClick={handleAddIngredientStep}
              >
                + Add Step
              </Button>
            </div>
          </Collapse>

          <Button type="submit" variant="contained" sx={{ width: 1, mt: 2 }}>
            Add Recipe
          </Button>
        </Container>
      </form>
    </Dialog>
  );
}
