import { useState } from "react";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps, AlertColor } from "@mui/material/Alert";
import { forwardRef } from "react";

const Alert = forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  },
);

const useSnackbar = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Snackbar parameters
  const [message, setMessage] = useState("");
  const [type, setType] = useState<AlertColor>("success");
  const [duration, setDuration] = useState(3000);
  const [position, setPosition] = useState<SnackbarOrigin>({
    vertical: "bottom",
    horizontal: "left",
  });

  const showSnackbar = (
    message: string,
    type: AlertColor = "success", // "error", "warning", "info"
    duration: number = 3000,
    position: any = {
      vertical: "bottom", // "top"
      horizontal: "left", // "right", "center"
    },
  ) => {
    setMessage(message);
    setType(type);
    setDuration(duration);
    setPosition(position);

    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const Snack = () => (
    <Snackbar
      open={snackbarOpen}
      anchorOrigin={position}
      autoHideDuration={duration}
      onClose={handleSnackbarClose}
    >
      <Alert severity={type}>{message}</Alert>
    </Snackbar>
  );

  return { showSnackbar, Snack };
};

export default useSnackbar;
