import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import RecipeLibraryTable from "../components/RecipeLibraryTable";
import AddRecipeForm from "../components/AddRecipeForm";
import { supabaseRPC } from "../config/supabaseClient";
import useSnackbar from "./useSnackbar";

// interface RecipeLibraryTableProps {
//   recipes: any;
//   handleRecipeDelete: (recipeId: number) => void;
// }

const RecipeLibrary = () => {
  const { showSnackbar, Snack } = useSnackbar();

  const [recipes, setRecipes]: any = useState(null);
  const [addingRecipe, setAddingRecipe] = useState(false);

  useEffect(() => {
    // Get recipes
    supabaseRPC("get_all_recipes", {}).then((res) => {
      setRecipes(res);
    });
  }, []);

  // Add Recipe Dialog
  const handleAddRecipe = () => {
    setAddingRecipe(true);
  };

  const handleClose = () => {
    setAddingRecipe(false);
  };

  // Snacks
  const handleSubmitSnack = (recipeName: string) => {
    showSnackbar(recipeName + " Added To Recipe Library");
  };

  const handleDeleteSnack = () => {
    showSnackbar("Recipe(s) Deleted", "info");
  };

  return (
    <>
      <Container sx={{ mt: 4 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <h1>Recipe Library</h1>
          <Button variant="contained" onClick={handleAddRecipe}>
            Add Recipe
          </Button>
        </Stack>

        <AddRecipeForm
          open={addingRecipe}
          onClose={handleClose}
          recipeSubmitSnack={handleSubmitSnack}
        />

        {recipes && (
          <RecipeLibraryTable
            recipes={recipes}
            recipeDeleteSnack={handleDeleteSnack}
          />
        )}

        <Snack />
      </Container>
    </>
  );
};

export default RecipeLibrary;
