import { createClient } from "@supabase/supabase-js";

// Grab supabase URL and public API key from env
const supabaseUrl: any = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey: any = process.env.REACT_APP_ANON_KEY;

const supabase: any = createClient(supabaseUrl, supabaseKey);

export const supabaseRPC = async (rpc: string, payload: any) => {
  const { data, error } = await supabase.rpc(rpc, payload);

  if (error) {
    console.log(error);
    return error;
  }

  if (data) {
    return data;
  }
};

export default supabase;
